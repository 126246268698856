@import "../../styles/Common/color.scss";
@import "../../styles/Common/font.scss";

.bins {
    & .bin {
        & .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 16px;
            gap: 8px;
            margin: 16px 0px;

            background: #fffffa;
            border: 2px solid $neutral-300;
            border-radius: 16px;

            cursor: pointer;

            &:hover {
                border: 2px solid $neutral-500;
            }

            &.selected {
                border: 2px solid $neutral-800;
            }

            & .row-1 {
                display: flex;
                justify-content: center;
                align-items: center;

                & .bin-name {
                    font-family: $secondary-font;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 150%;
                    color: $neutral-800;
                }
            }

            & .row-2 {
                font-family: $secondary-font;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: $neutral-800;
            }
        }
    }
}

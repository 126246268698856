.welcome {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #f6f5ef;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    & .content {
        width: 410px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & .message {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            margin: 30px auto;

            & span {
                font-weight: 500;
            }
        }

        & .loader {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 0 auto;
        }
    }
}
iframe {
    width: 980px;
    height: 750px;
}

@media screen and (min-width: 600px) and (max-width: 1750px) {
    iframe {
        width: 64vw;
        height: calc(59vw * 750 / 905);
    }
}

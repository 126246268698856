@import "../../styles/Common/color.scss";
@import "../../styles/Common/font.scss";

.settlement-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 24px;
    color: $accent-color-27;
    .commodity {
        font-family: $primary-font;
        font-weight: 500;
        font-size: 20px;
        color: $neutral-800;
    }
    .cols {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: flex-start;
        height: 50px;
        .col-1 {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            font-family: $secondary-font;
            font-style: normal;
            font-size: 13px;
            color: rgba(66, 67, 67, 0.73);

            span {
                padding-right: 91px;
            }
            span:nth-child(2) {
                font-weight: 400;
                font-size: 18px;
                color: $neutral-800;
                margin-top: 10px;
            }
        }

        .col-3 {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            margin-left: auto;
        }
    }
}

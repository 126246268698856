@import "../../styles/Common/color.scss";
@import "../../styles/Common/font.scss";

.main {
    margin: 0 auto;
    padding-bottom: 30px;

    & .load {
        min-height: 712px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .back {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        & .icon {
            margin-left: 10px;
            margin-right: 7px;
        }

        & .caption {
            color: $quinary-color;
            font-family: $secondary-font;
            font-size: 14px;
            font-weight: 500;
            line-height: 23px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    & .content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: top;
        min-height: 712px;

        position: relative;

        & .details {
            max-width: 964px;
            margin-top: 100px;
            width: 100%;
            background-color: $accent-color-27;
            border-radius: 16px;
            padding: 16px;
            box-shadow: 0px 5px 15px #0000000d;

            .top-bar {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 30px;
            }

            & .navigations {
                width: 100%;
                margin-bottom: 30px;
            }
        }
    }
}

.icon-button {
    cursor: pointer;
    padding: 1rem;
    border-radius: 50%;
    &:hover {
        transition: all 0.5s ease;
        background-color: rgba(66, 67, 67, 0.1);
        .kebab-tooltip,
        .close-tooltip {
            display: block;
        }
    }
}

.add-file-menu {
    z-index: 1;
    position: absolute;
    width: auto;
    top: 57px;
    right: 275px;
}
.kebab-menu {
    z-index: 1;
    position: absolute;
    width: auto;
    top: 66px;
    right: -1px;
}

.contractNo {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 18px;
    color: $quinary-color;
}

.kebab-tooltip,
.close-tooltip {
    display: none;
    position: absolute;
    top: 14px;
    background: #5b5c5c;
    border-radius: 4px;
    padding: 2px 8px;
    color: #ffffff;
    font-size: 13px;
    font-family: $secondary-font;
}
.kebab-tooltip {
    right: 16px;
}

.close-tooltip {
    right: 60px;
}

@import "../../styles/Common/color.scss";

* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    height: 100%;
    overflow-y: overlay;
}

body {
    background-color: $accent-color-27;
    width: 100%;
    min-height: 100vh;
    overflow-anchor: none;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

:global(#ui) {
    width: 100%;
    min-height: 100vh;
}

@import "../../../styles/Common/color.scss";
@import "../../../styles/Common/device.scss";
@import "../../../styles/Common/font.scss";

.main {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    justify-content: flex-start;

    .dockage-label {
        margin: 2px 8px;
        padding: 4px 8px;
        font-size: 13px;
        font-family: $secondary-font;
        border-radius: 4px;
    }
    .Good {
        color: #45a366;
        background: rgba(#45a366, 0.1);
    }
    .Average {
        color: #aa9936;
        background: rgba(#aa9936, 0.1);
    }
    .High {
        color: #d63737;
        background: rgba(#d63737, 0.1);
    }

    & .item {
        text-align: left;
        white-space: nowrap;
        margin-right: 5px;
        padding-left: 8px;
        display: flex;
        gap: 4px;
        flex-direction: column;

        & .key {
            color: rgba($quinary-color, 0.7);
            font-family: $secondary-font;
            font-size: 12px;
            font-weight: 400;
            line-height: 23px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            & span {
                margin-right: 4px;

                & svg {
                    width: auto;
                    height: 9px;
                }
            }
        }

        & .value {
            color: $quinary-color;
            font-family: $secondary-font;
            font-size: 18px;
            font-weight: 400;
            line-height: 23px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &.is-delivery-card {
        grid-template-columns: 0.5fr 1fr 0.5fr 0.75fr;
    }
    &.is-financial-card {
        grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
    }

    &.is-select-contract {
        grid-template-columns: 0.8fr 0.8fr 1.5fr;
    }
    &.allocate-overage {
        width: 360px;
        grid-template-columns: 3fr 2.8fr;
    }
    &.is-my-listing {
        & .item {
            width: 120px;
        }
    }
}

@media screen and (max-width: $mobile-max-width) {
    .main {
        display: block;

        & .item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            & .key {
                color: rgba($quinary-color, 0.7);
                font-family: $secondary-font;
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;

                & span {
                    display: none;
                }
            }

            & .value {
                color: $quinary-color;
                font-family: $secondary-font;
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
            }
        }
    }
}

@import "../../../styles/Common/color.scss";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($tertiary-color, 0.65);
    z-index: 10;
}

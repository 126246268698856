@import "../../../styles/Common/color.scss";
@import "../../../styles/Common/font.scss";

.main {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: auto;
    padding-top: 24px;
}
.selectedTab {
    border-bottom: 4px solid #b8ad69 !important;
}

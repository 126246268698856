@import "../../../styles/Common/color.scss";
@import "../../../styles/Common/device.scss";

.modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    height: auto;
    background-color: $accent-color-27;
    border-radius: 10px;
    text-align: center;

    &.large {
        width: 714px;
        max-width: 714px;
    }

    &.small {
        width: 522px;
        max-width: 522px;
    }
}

@media screen and (max-width: $mobile-max-width) {
    .modal {
        border-radius: 15px;

        &.large,
        &.small {
            width: 85%;
            min-width: 310px;
        }
    }
}

@import "../../styles/Common/color.scss";
@import "../../styles/Common/font.scss";

.section {
    .heading {
        font-family: $secondary-font;
        font-style: normal;
        font-weight: 500;
        color: $neutral-800;
        text-align: left;
        margin: 24px 0;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 1px;
    }
}

/*
 * @Author: Venkataramana L J 
 * @Date: 2022-04-25 22:23:31 
 * @Last Modified by: Swetha Paipalle
 * @Last Modified time: 2023-11-27 13:18:06
 */

.card-col {
    flex: 1;
    .icon {
        display: flex;
        align-items: center;
        width: 200px;
        svg {
            height: 20px;
            width: auto;
        }
    }
}
.tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-weight: 500;
    padding: 4px 12px 4px 8px;
    line-height: 21px;
    border-radius: 8px;
    white-space: nowrap;
}

@import "../../../styles/Common/color.scss";
@import "../../../styles/Common/font.scss";

.load {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main {
    gap: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 16px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.0588235);
    border-radius: 12px 12px 0px 0px;
    &.green {
        background: linear-gradient(106deg, #419c61 12.54%, #1e4f2f 82.53%);
    }
    &.yellow {
        background: linear-gradient(105.98deg, #c59e32 12.54%, #635327 82.53%);
    }
    &.red {
        background: linear-gradient(106deg, #cb4141 12.54%, #531b1b 82.53%);
    }
    &.blue {
        background: linear-gradient(105.98deg, #5387d4 12.54%, #2157a7 82.53%);
    }

    &.ocr {
        justify-content: center;
        align-items: center;
        gap: 8px;
    }

    &.section-page {
        width: 420px;
        justify-content: space-between;
        align-items: center;
        padding: 8px 8px 8px 12px;
        gap: 16px;
        border-radius: 12px 12px 0px 0px;
        box-shadow: 0px 8px 10px 5px rgba(0, 0, 0, 0.16);
    }
}

@import "../../../styles/Common/color.scss";
@import "../../../styles/Common/font.scss";

.main {
    width: 100%;
    min-height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & .icon {
        width: 19px;
        height: 19px;
        margin-right: 3px;
        display: flex;
        justify-content: center;
        align-items: center;

        & svg {
            width: 14px;
            height: 14px;
        }
    }

    & .caption {
        color: $accent-color-14;
        font-family: $secondary-font;
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
    }
}

@import "../../../styles/Common/color.scss";
@import "../../../styles/Common/font.scss";
@import "../../../styles/Common/device.scss";

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.main {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    padding: 0 10px;

    & .icon {
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 5px;
        position: absolute;
    }

    & .caption {
        color: $pure-white;
        line-height: 26px;
    }

    & .arrow {
        filter: brightness(100);
    }
}

.is-arrow {
    justify-content: space-evenly;
    & .caption {
        margin-left: 19px;
    }
}

.is-ellipsis {
    & .caption {
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.is-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    & img {
        animation: spin 800ms linear infinite;
    }
}

.gold {
    background-color: $secondary-color;

    &:hover {
        background-color: $accent-color-21;
    }
}
.grey {
    background-color: $accent-color-7;

    &:hover {
        background-color: $accent-color-8;
    }
}
.red {
    background-color: $accent-color-9;

    &:hover {
        background-color: $accent-color-10;
    }
}
.blue {
    background-color: $accent-color-23;

    &:hover {
        background-color: $accent-color-24;
    }
}
.light-grey {
    background-color: rgba(66, 67, 67, 0.1);

    & .caption {
        color: $neutral-800;
    }

    &:hover {
        background-color: rgba(66, 67, 67, 0.25);
    }
}

.white {
    border: 1.5px solid #424343;
    border-radius: 60px;
    background: #ffffff;

    & .caption {
        color: $neutral-800;
    }

    &:hover {
        background: rgba(66, 67, 67, 0.1);
    }
}

.icon-left {
    & .icon {
        left: 12px;
        right: auto;
    }
}
.icon-right {
    & .icon {
        left: auto;
        right: 12px;
    }
}

.primary {
    &.large {
        height: 50px;
        border-radius: 25px;

        & .icon {
            height: 50px;
        }

        & .caption {
            font-family: $primary-font;
            font-size: 17px;
            font-weight: 500;
        }

        &.is-loading {
            width: 50px;

            & img {
                width: 30px;
                height: 30px;
            }
        }
    }
    &.medium {
        height: 40px;
        border-radius: 60px;

        & .caption {
            font-family: $secondary-font;
            font-size: 14px;
            font-weight: 500;
        }

        &.is-loading {
            width: 40px;

            & img {
                width: 25px;
                height: 25px;
            }
        }
    }
    &.small {
        height: 30px;
        border-radius: 15px;

        & .icon {
            height: 30px;
        }

        & .caption {
            font-family: $secondary-font;
            font-size: 12px;
            font-weight: 500;
        }

        &.is-loading {
            width: 30px;

            & img {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.secondary {
    border-radius: 6px;

    &.large {
        height: 40px;

        & .icon {
            height: 40px;
        }

        & .caption {
            font-family: $secondary-font;
            font-size: 16px;
            line-height: 40px;
        }

        &.is-loading {
            width: 40px;

            & img {
                width: 30px;
                height: 30px;
            }
        }
    }
    &.small {
        height: 35px;

        & .icon {
            height: 35px;
        }

        & .caption {
            font-family: $secondary-font;
            font-size: 14px;
            line-height: 35px;
        }

        &.is-loading {
            width: 35px;

            & img {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.is-disabled {
    color: rgba($pure-white, 0.6);
    cursor: auto;

    &.gold {
        background-color: rgba($secondary-color, 0.6);

        &:hover {
            background-color: rgba($secondary-color, 0.6);
        }
    }

    &.red {
        background-color: rgba($accent-color-9, 0.6);

        &:hover {
            background-color: rgba($accent-color-9, 0.6);
        }
    }

    &.blue {
        background-color: rgba($accent-color-23, 0.6);

        &:hover {
            background-color: rgba($accent-color-23, 0.6);
        }
    }

    &.green {
        background-color: rgba($accent-color-2, 0.6);

        &:hover {
            background-color: rgba($accent-color-2, 0.6);
        }
    }
}

.rounded {
    border-radius: 25px !important;
}

@import "../../../styles/Common/color.scss";
@import "../../../styles/Common/font.scss";

.status {
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.caption {
    font-size: 14px;
    font-weight: 500;
    font-family: $secondary-font;
    white-space: nowrap;
    display: inline-flex;
    gap: 8px;
    align-items: center;
}

.contract-banner {
    border-radius: 8px;
    padding: 8px 16px 8px 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: 500;
    width: 960px;

    &.open {
        background-color: rgba(65, 156, 97, 0.12);
        .caption {
            color: #347a4c;
        }
    }

    &.closed {
        background-color: rgba(83, 135, 212, 0.12);
        .caption {
            color: #4178c4;
        }
    }

    &.unpriced {
        background-color: #faf1c0;
        .caption {
            color: #615930;
        }
    }

    &.untriggered {
        background-color: #faf1c0;
        .caption {
            color: #615930;
        }
    }

    &.triggered {
        &.open {
            background-color: rgba(65, 156, 97, 0.12);
            .caption {
                color: #347a4c;
            }
        }
        &.closed {
            background-color: rgba(83, 135, 212, 0.12);
            .caption {
                color: #4178c4;
            }
        }
    }
    &.offer-expired {
        background-color: rgba(66, 67, 67, 0.08);
        .caption {
            color: #424343;
        }
    }
    &.offer-triggered {
        background-color: rgba(65, 156, 97, 0.12);
        .caption {
            color: rgba(52, 122, 76, 1);
        }
    }
    &.offer-untriggered {
        background-color: rgba(250, 241, 192, 1);
        .caption {
            color: #615930;
        }
    }
    &.other-files {
        background-color: #f0efe6;
        .caption {
            color: #424343;
        }
    }

    .title {
        display: flex;
        justify-content: space-between;

        .col {
            display: flex;
            align-items: center;
            gap: 16px;
        }
    }
}

@import "../../../../styles/Common/color.scss";
@import "../../../../styles/Common/font.scss";

.main {
    width: 100%;

    & .content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.error {
    min-height: 30px;
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.ctr-error {
        justify-content: center;
        padding-left: 0px;
    }

    & .caption {
        color: $accent-color-14;
        font-family: $secondary-font;
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
    }
}

.fieldset {
    height: 50px;
    border-radius: 6px;
    border: 1px solid rgba($quinary-color, 0.4);
    position: relative;

    &.is-focused,
    &.has-text {
        border: 1px solid $quinary-color;
        transition: border 0.19s ease-in-out;
    }

    &.has-error {
        border: 1px solid $accent-color-14;
    }

    & .input-group {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: transparent;

        & .input {
            width: 38px;
            height: 90%;
            color: $quinary-color;
            font-family: $secondary-font, monospace;
            font-size: 19px;
            font-weight: 500;
            line-height: 26px;
            padding: 0;
            outline: none;
            border: none;
            background-color: transparent;
            overflow: hidden;
            text-align: center;
        }
    }

    & .divider {
        & span {
            display: block;
            width: 2px;
            height: 6px;
            background-color: rgba($quinary-color, 0.3);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
    }
}

.is-dark {
    & .fieldset {
        border: 1px solid rgba($accent-color-6, 0.5);

        &.is-focused,
        &.has-text {
            border: 1px solid $secondary-color;
        }

        &.has-error {
            border: 1px solid $accent-color-14;
        }

        & .input-group {
            & .input {
                color: $pure-white;
            }
        }

        & .divider {
            & span {
                background-color: rgba($accent-color-6, 0.5);
            }
        }
    }
}

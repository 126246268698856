@import "../../../styles/Common/color.scss";
@import "../../../styles/Common/font.scss";

.load {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($pure-white, 0.3);
    z-index: 2;
}

.empty {
    width: 632px;
    height: 146px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba($quinary-color, 0.2);
    background-color: rgba($accent-color-26, 0.2);
    border-radius: 9px;
    cursor: pointer;
    position: relative;

    &:hover {
        border: 1px solid rgba($quinary-color, 0.7);
        background-color: rgba($accent-color-26, 0.7);
    }

    &.is-load {
        cursor: auto;

        &:hover {
            border: 1px solid rgba($quinary-color, 0.2);
            background-color: rgba($accent-color-26, 0.2);
        }
    }

    & .uploader {
        display: none;
    }

    & .icon {
        margin-bottom: 10px;
    }

    & .caption {
        color: $quinary-color;
        font-family: $secondary-font;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
    }
}

.error {
    margin-left: 5px;
}

.image {
    width: 147px;
    height: 147px;
    position: relative;
    cursor: pointer;
    margin-bottom: 30px;

    & .cell {
        & img {
            width: 147px;
            height: 147px;
            border-radius: 9px;
            object-fit: cover;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            opacity: 0;
            transition: all 0.5s;
        }

        &::after {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba($pure-black, 0.5);
            border-radius: 6px;
        }

        &::before {
            width: 30px;
            height: 30px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: url("../../../graphics/common/eye_white_icon.svg") no-repeat;
            z-index: 1;
        }

        &:hover::before,
        &:hover::after {
            opacity: 1;
        }
    }

    & .remove {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        box-shadow: 0px 1px 2px #00000060;
        cursor: pointer;

        & svg {
            width: 24px;
            height: 24px;
        }
    }
}

.upload_card {
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin-bottom: 16px;
    width: 634px;
    background-color: rgba($accent-color-27, 1);
    border: 1px solid rgba($accent-color-26, 1);
    border-radius: 16px;

    & .cloumn-1 {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;

        & .close-bg {
            cursor: pointer;
            opacity: 0.4;
            &:hover {
                opacity: 1;
            }
        }
        & .close-x {
            pointer-events: none;
            margin-top: -32px;
        }
    }

    & .cloumn-2 {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 17px;
        background-color: rgba($accent-color-26, 0.2);
        border: 1px solid $quinary-color;
        border-radius: 12px;
        margin: 15px 0 15px 0;
        cursor: pointer;

        & .icon {
            margin-right: 15px;
        }

        & .caption {
            text-align: left;

            & .name {
                color: $quinary-color;
                font-family: $secondary-font;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
            }
            & .view {
                color: rgba($quinary-color, 0.7);
                font-family: $secondary-font;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
            }
        }
    }

    & .cloumn-2:hover {
        background-color: rgba($accent-color-26, 1);
    }

    & .cloumn-3 {
        display: flex;
        & .cropYear {
            width: 100%;
        }
    }
}

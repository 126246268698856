@import "../../../styles/Common/color.scss";
@import "../../../styles/Common/font.scss";

.file {
    width: 100%;
    height: 97px;
    background-color: rgba($accent-color-26, 0.2);
    border-radius: 9px;
    border: 1px solid rgba($quinary-color, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    & .column-1 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 15px;

        & .icon {
            margin-right: 15px;
        }

        & .caption {
            text-align: left;

            & .name {
                color: $quinary-color;
                font-family: $secondary-font;
                font-size: 16px;
                font-weight: 500;
                line-height: 23px;
                max-width: 300px;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            & .size {
                color: rgba($quinary-color, 0.7);
                font-family: $secondary-font;
                font-size: 12px;
                font-weight: 500;
                line-height: 19px;
            }
        }
    }

    & .column-2 {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & .button {
            width: 108px;
            display: flex;
            align-items: center;
        }
    }
}

@import "styles/Common/color.scss";
@import "styles/Common/font.scss";
@import "styles/Common/device.scss";

.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 24px;
    justify-content: flex-start;
    color: $neutral-800;
    text-align: center;
    margin: auto;
    .icon {
        padding-top: 40px;
    }

    .title {
        font-size: 20px;
        font-weight: 500;
        font-family: $primary-font;
        line-height: 30px;
    }

    .desc {
        font-size: 16px;
        font-family: $secondary-font;
        width: 442px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .button {
        width: 442px;
        height: 48px;
    }
}

.form-header {
    color: $quinary-color;
    font-family: $primary-font;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    padding-top: 40px;
    padding-bottom: 24px;
}

.load {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-contract-form {
    width: calc(min(90%, 640px));
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    gap: 16px;
    padding-bottom: 76px;

    .contract-card {
        width: 100%;
        border-radius: 20px;
        border: 2px solid #e3e3da;
        background: #fffffa;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.0588235294);
        cursor: pointer;
        display: flex;
        justify-content: space-around;
        flex-flow: column;
        padding: 24px;
        gap: 16px;

        &:hover,
        &.is-selected {
            border: 2px solid rgba($quinary-color, 0.7);
        }

        .header-row {
            height: 50px;
            display: flex;
            justify-content: space-between;
            .first-col {
                display: flex;
                flex-flow: column;
                font-family: $secondary-font;
                line-height: 150%;
                justify-content: space-around;
                gap: 4px;
                div {
                    display: flex;
                    align-items: center;
                    font-size: 17px;
                    font-weight: 700;
                    span {
                        margin-left: 5px;
                    }
                }
                > span {
                    font-size: 14px;
                }
            }
        }
    }
}

.commodity-name {
    font-size: 20px;
    font-family: $primary-font;
    text-align: left;
}

.contract-no {
    font-size: 16px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $quinary-color;
}

.radio-icon {
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid rgba($quinary-color, 0.4);
}

.long-button {
    display: flex;
    width: 100%;
    border: 2px solid #e3e3da;
    background: #fffffa;
    box-shadow: 0px 0px 6px 0px #0000000f;
    border-radius: 16px;
    font-size: 14px;
    font-family: $secondary-font;
    font-weight: 500;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    padding: 12px;
    cursor: pointer;
    margin-bottom: 4px;
    &.disabled {
        opacity: 0.38;
        cursor: default;
    }
}
.long-button:hover {
    background: rgba(#e3e2da, 0.5);
}
.long-button.disabled:hover {
    background: #fffffa;
}

.main {
    width: 640px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background: rgba($accent-color-27, 1);
}
.header {
    display: flex;
    flex-direction: column;
    font-family: $secondary-font;
    font-size: 15px;
    color: rgba($quinary-color, 1);
    padding: 24px 0 16px 0px;
}

.load_ticket_box {
    width: 640px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    padding: 12px;
    background: rgba($accent-color-27, 1);
    border: 1px solid #e3e3da;
    border-radius: 16px;

    .first_item {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .header_first_item {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        gap: 4px;
    }
}

.mainDiv {
    padding: 4px;
    width: 100%;
}

.mainContent {
    display: flex;
    flex-direction: row;
    gap: 70px;
    margin-top: 16px;
}

.secondContentRow {
    display: flex;
    flex-direction: row;
    gap: 44px;
}

.thirdContentRow {
    display: flex;
    flex-direction: row;
    gap: 44px;
}

@import "../../../styles/Common/color.scss";
@import "../../../styles/Common/font.scss";

.main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background-color: rgba($quinary-color, 0.7);
    z-index: 10;

    & .navigation {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 63px;
        background-color: $pure-black;
        z-index: 2;

        & .nav-container {
            width: 993px;
            height: 100%;
            text-align: center;
            margin: 0 auto;
            padding: 0 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            & .title {
                color: $pure-white;
                font-family: $primary-font;
                font-size: 20px;
                font-weight: 400;
                line-height: 29px;
                position: absolute;
                top: 18px;
                left: 50%;
                transform: translateX(-50%);
            }

            & .close {
                opacity: 0.7;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    & .content {
        width: 993px;
        height: 100vh;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 5px 15px #0000000d;
        margin: 0 auto;

        & img {
            position: absolute;
            top: calc(50% + 33px);
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 880px;
            max-height: 80vh;
        }

        & .button {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $pure-black;
            cursor: pointer;
            position: relative;

            &.hidden {
                visibility: hidden;
            }

            & span {
                width: 9.1px;
                height: 9.1px;
                border: solid $pure-white;
                border-width: 2px 2px 0 0;
                position: absolute;
            }

            &:first-child {
                & span {
                    transform: rotate(-135deg);
                    top: 15.5px;
                    left: 17px;
                }
            }

            &:last-child {
                & span {
                    transform: rotate(45deg);
                    top: 15.5px;
                    right: 17px;
                }
            }

            &:hover {
                transition: all 0.2s ease-out;
                background-color: $secondary-color;
            }
        }
    }
}

@import "../Common/color.scss";
@import "../Common/font.scss";
@import "../Common/device.scss";

.main {
    width: 100%;
    height: auto;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & .close {
        position: absolute;
        right: 40px;
        top: 40px;
        cursor: pointer;
        opacity: 0.7;

        &:hover {
            opacity: 1;
        }
    }

    & .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto 20px auto;

        & img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
        }
    }

    & .title {
        font-family: $primary-font;
        font-weight: 500;
        font-size: 17px;
        line-height: 150%;
        text-align: center;
        color: $neutral-800;
        margin: 0 auto 20px auto;
    }

    & .description {
        color: $quinary-color;
        font-family: $secondary-font;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        margin: -10px auto 25px auto;
    }

    & .button {
        width: 246px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        & .button {
            width: 144px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:first-child {
                margin-right: 20px;
            }

            &:last-child {
                margin-right: 0px;
            }
        }
    }

    & .buttons-vertical {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        & .button {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    & .link {
        color: $quinary-color;
        font-family: $primary-font;
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        cursor: pointer;
        text-align: center;
        margin: 15px auto 0 auto;

        &:hover {
            text-decoration: underline;
        }
    }

    & .separator {
        width: 100%;
        height: 1px;
        background-color: rgba($quinary-color, 0.1);
        margin: 30px auto;
    }

    & span {
        font-weight: 700;
    }
}

@media screen and (max-width: $mobile-max-width) {
    .main {
        padding: 20px 15px;

        & .icon {
            margin: 0 auto 10px auto;
        }

        & .title {
            font-size: 17px;
            line-height: 24px;
        }
    }
}

@import "../../../../styles/Common/color.scss";
@import "../../../../styles/Common/font.scss";
@import "../../../../styles/Common/device.scss";

.main {
    & .top {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 63px;
        background-color: $primary-color;
        z-index: 10;

        & .container {
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            margin: 0 auto;
            position: relative;
            padding: 0px 8px 0px 16px;
            & .close {
                opacity: 0.7;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                }
            }

            & .title {
                color: $pure-white;
                font-family: $primary-font;
                font-size: 20px;
                font-weight: 400;
                line-height: 31px;
                position: absolute;
                top: 18px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    & .content {
        background-color: #fffffa;
        overflow-y: scroll;
        &.is-dark {
            background-color: #f6f5ef;
        }
        // position: absolute;
        // width: 100%;
    }

    & .bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 67px;
        background-color: $quaternary-color;
        border-top: 1px solid $accent-color-26;
        z-index: 5;

        & .container {
            height: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            align-items: center;
            text-align: center;
            margin: auto;
            position: relative;
            padding: 0 8px 0 16px !important;

            & .buttons {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                & .button {
                    width: 220px;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $tablet-max-width) {
    .main {
        width: 100%;

        & .top {
            & .container {
                width: 100%;
                padding: 16px;

                & .title {
                    font-size: 20px;
                }
            }
        }

        & .content {
            position: relative;
            height: calc(100vh - 128px);
        }

        & .bottom {
            & .container {
                width: 100%;

                & .buttons {
                    width: 100%;
                    padding: 16px;
                }

                & .buttons:nth-child(2) {
                    justify-content: flex-start;
                }

                & .buttons:nth-child(1) {
                    justify-content: flex-end;
                }
            }
        }
    }
}

/* Common */
$pure-white: #fff;
$pure-black: #000;

/* Main */
$primary-color: #31524f;
$secondary-color: #b8ad69;
$tertiary-color: #283935;
$quaternary-color: #f6f5ef;
$quinary-color: #3f444d;

/* Accent */
$accent-color-1: #374e49; // Replace by $primary-color
$accent-color-2: #63817a;
$accent-color-3: #938d6b;
$accent-color-4: #713131; // Remove
$accent-color-5: #c4b966; // Replace by $secondary-color
$accent-color-6: #b8d9d1;
$accent-color-7: #7f8185;
$accent-color-8: #707174;
$accent-color-9: #de5757;
$accent-color-10: #c95050;
$accent-color-11: #5c716c;
$accent-color-12: #c9d8bc;
$accent-color-13: #868163; // Replace with $quinary-color
$accent-color-14: #d57f7f;
$accent-color-15: #9ba8a1; // Remove
$accent-color-16: #d9e3cf; // Replace by $accent-color-12
$accent-color-17: #d0cebe; // Remove
$accent-color-18: #ecebe3; // Replace by $accent-color-26
$accent-color-19: #92bf6c;
$accent-color-20: #83885f; // Remove
$accent-color-21: #a89e5f;
$accent-color-22: #30353c;
$accent-color-23: #528ad9;
$accent-color-24: #406dac;
$accent-color-25: #f2f1eb;
$accent-color-26: #e3e2da;
$accent-color-27: #fffffa;
$accent-color-28: #c4c6c5; // Only used on light mode guide line
$accent-color-29: #b2b4b4;
$accent-color-30: #e3ae87; // Only used in tpo badge
$accent-color-31: #a0d1b1;
$accent-color-32: #45a366;

/*  Warning  */
$warning-600: #aa9936;
$warning-400: #dbc449;
$warning-100: #f8efbd;
$warning-200: #f0e187;

/*  Connect  */
$connect-50: #eff6fa;
$connect-500: #538ad8;
$connect-900: #234069;

/*  Neutral  */
$neutral-50: #fffffa;
$neutral-100: #f6f5ef;
$neutral-200: #f0efe6;
$neutral-300: #e3e3da;
$neutral-400: #cecec6;
$neutral-500: #a8a8a3;
$neutral-800: #424343;

/*  Danger  */
$danger-50: #fbefe9;
$danger-100: #f6d3ce;
$danger-200: #f2bcb4;
$danger-600: #d64040;

/*  Success  */
$success-50: #ecf6eb;
$success-200: #c0e2cd;
$success-300: #8fd0a6;
$success-600: #45a366;

/*  Grey  */
$grey-50: #f2f2f2;
$grey-100: #d9d9d9;
$grey-300: #a1a1a1;

/*  Secondary  */
$secondary-50: #f7f4dc;
$secondary-100: #f0ecc9;
$secondary-200: #e3d99a;
$secondary-300: #ccc17c;

@import "../../../../styles/Common/color.scss";
@import "../../../../styles/Common/device.scss";
@import "../../../../styles/Common/font.scss";

@media screen and (max-width: $mobile-max-width) {
    .main {
        width: 100%;
        height: 100%;
        padding: 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & .content {
            min-width: 300px;
            padding-top: 40px;

            & .icon {
                text-align: center;
                margin: 0 auto 20px auto;
            }

            & .title {
                color: $quinary-color;
                font-family: $primary-font;
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                margin: 0 auto;
            }

            & .description {
                color: $quinary-color;
                font-family: $secondary-font;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                margin: 10px auto 20px auto;
            }

            & .download {
                display: flex;
                justify-content: center;
                align-items: center;

                & a {
                    cursor: pointer;

                    &:first-child {
                        margin-right: 13px;
                    }

                    & img {
                        width: auto;
                        height: 47px;
                    }
                }
            }

            & .link {
                color: $quinary-color;
                font-family: $secondary-font;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                margin: 100px auto 0 auto;

                & a {
                    color: $accent-color-23;
                }
            }
        }
    }
}

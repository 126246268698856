@import "styles/Common/color.scss";
@import "styles/Common/font.scss";

.banner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 8px;
    width: 100%;
    height: 56px;
    background: $warning-100;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.0588235);
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    min-height: calc(100vh - 126px);
    max-height: calc(100vh - 126px);
    padding-top: 40px;

    & .icon {
        width: 112px;
        height: 112px;
    }

    & .buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        & .button {
            width: 440px;
            height: 56px;
            background-color: $neutral-50;
            border: 2px solid $neutral-300;
            border-radius: 16px;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;

            font-family: $secondary-font;
            font-style: normal;
            font-size: 16px;
            line-height: 150%;
            &:hover {
                border: 2px solid $neutral-500;
            }

            &.selected {
                border: 2px solid $neutral-800;
            }
        }
    }
}

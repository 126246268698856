@import "../../../../styles/Common/color.scss";
@import "../../../../styles/Common/font.scss";

.main {
    width: 100%;
    position: relative;
    height: 85vh;
    max-height: 681px;

    & .header {
        width: 100%;
        height: 63px;
        background-color: $quaternary-color;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid rgba($quinary-color, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        & .caption {
            color: $quinary-color;
            font-family: $primary-font;
            font-size: 20px;
            font-weight: 400;
            line-height: 31px;
            white-space: nowrap;
        }

        & .icon {
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            opacity: 0.7;

            &:hover {
                opacity: 1;
            }
        }
    }

    & .footer {
        width: 100%;
        height: 80px;
        background-color: $quaternary-color;
        border-radius: 0 0 10px 10px;
        border-top: 1px solid rgba($quinary-color, 0.2);
        z-index: 2;
    }
}

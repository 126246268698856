@import "../../styles/Common/color.scss";

.main {
    position: relative;
    background-color: $quaternary-color;
    width: 100%;
    min-height: 100vh;
    // padding-bottom: 130px;

    & .content {
        padding-top: 63px;
        overflow: hidden;
    }

    & .copy-right {
        position: absolute;
        left: 1%;
        bottom: 10px;
    }
}

.load {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

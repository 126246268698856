@import "../../../styles/Common/color.scss";
@import "../../../styles/Common/font.scss";

.options {
    display: flex;
    flex-direction: column;
    text-align: left;
    & .option {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
}

.show-link {
    width: min-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $quinary-color;
    cursor: pointer;

    & .caption {
        color: $quinary-color;
        font-family: $secondary-font;
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        margin-right: 10px;
        white-space: nowrap;
    }
}

.footer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    & .button {
        width: 246px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .clear-all {
        position: absolute;
        top: 29px;
        left: 40px;
        color: $quinary-color;
        font-family: $primary-font;
        font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

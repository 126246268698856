.loadTicketContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
}

.verticalDivider {
    border-left: 1px solid #f0efe6;
    height: 100%;
}

@import "../../../styles/Common/color.scss";
@import "../../../styles/Common/font.scss";

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

.main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 420px;
    height: auto;
    background-color: transparent;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0;

    & .icon {
        margin-bottom: 30px;
    }

    & .spinner {
        & img {
            width: 30px;
            height: 30px;
        }
    }

    & .caption {
        color: $secondary-color;
        font-family: $secondary-font;
        font-style: italic;
        line-height: 23px;
        font-size: 16px;
        text-align: center;
        margin: 20px auto 0 auto;

        &:after {
            content: "\2026"; /* ascii code for the ellipsis character */
            color: $secondary-color;
            font-family: $secondary-font;
            font-style: italic;
            font-size: 16px;
            overflow: hidden;
            position: absolute;
            display: inline-block;
            vertical-align: bottom;
            text-align: left;
            animation: ellipsis steps(5, end) 1s infinite;
            width: 0px;
            padding-left: 1px;
        }
    }
}

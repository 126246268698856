@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spinner {
    width: auto;
    height: auto;
    animation: spin 800ms linear infinite;
}

@import "../../../styles/Common/color.scss";
@import "../../../styles/Common/font.scss";
@import "../../../styles/Common/device.scss";

.main {
    & .top {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 63px;
        background-color: $primary-color;
        z-index: 10;

        & .container {
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            margin: 0 auto;
            position: relative;
            padding: 0px 8px 0px 16px;
            & .close {
                opacity: 0.7;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                }
            }

            & .title {
                color: $pure-white;
                font-family: $primary-font;
                font-size: 20px;
                font-weight: 400;
                line-height: 31px;
                position: absolute;
                top: 18px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    & .content {
        background-color: $accent-color-27;
        height: calc(100vh - 129px);
        overflow-y: scroll;
    }

    & .bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 67px;
        background-color: $quaternary-color;
        border-top: 1px solid $accent-color-26;
        z-index: 5;

        & .container {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: auto;
            position: relative;
            gap: 12px;
        }
    }
}

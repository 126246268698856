@import "../../styles/Common/color.scss";
@import "../../styles/Common/font.scss";
@import "../../styles/Common/device.scss";

.load {
    width: 100%;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main {
    width: 100%;
    max-width: 1040px;
    padding: 0 20px;
    height: auto;
    text-align: center;
    margin: 0 auto;
    margin-top: 3rem;

    .separator {
        width: 100%;
        height: 1px;
        background-color: $neutral-400;
        margin: 10px auto 20px auto;
    }
}

@media screen and (max-width: $mobile-max-width) {
    .main {
        .plans {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
            width: auto;
            padding-bottom: 24px;
            .plan {
                width: 100%;
                height: 100%;
                min-width: unset;
            }
        }
    }
}
